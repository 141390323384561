import { ChampNavbar } from "../../../components/Navbar/champNavbar";
import { ChampSideNavBar } from "../../../components/Navbar/champNavbar";
const ResourcePage = () => {
  return (
    <>
      <ChampNavbar />
      <ChampSideNavBar />
    </>
  );
};
export default ResourcePage;
